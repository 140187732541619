<template>
  <CHeader with-subheader class="c-header-dark">
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand
      v-bind:class="{
        'd-lg-none': $store.state.sidebarShow,
        'd-sm-down-none': true,
      }"
      width="190"
      height="46"
    >
      <img
        width="156"
        height="40"
        alt="Loonity Logo"
        src="./../../public/loonity/brand/LoonityLogoWhite.svg"
      />
    </CHeaderBrand>

    <CHeaderNav class="mx-auto">
      <CHeaderNavItem class="px-1">
        <CHeaderNavLink to="/">
          <CButton size="sm" color="light" block
            ><b>{{ $t("header.buy") }}</b></CButton
          >
        </CHeaderNavLink>
      </CHeaderNavItem>

      <CHeaderNavItem class="px-1">
        <CHeaderNavLink>
          <CButton size="sm" color="light" block @click="WorkWithUs()">{{
            $t("header.work")
          }}</CButton>
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <!-- <CHeaderNavItem class="d-md-down-none px-3">
        <button
          @click="() => $store.commit('toggle', 'darkMode')"
          class="c-header-nav-btn"
        >
          <CIcon v-if="$store.state.darkMode" name="cil-sun" />
          <CIcon v-else name="cil-moon" />
        </button>
      </CHeaderNavItem>
      <TheHeaderDropdownNotif />
      <TheHeaderDropdownTasks />
      <TheHeaderDropdownMssgs /> -->
      <CHeaderNavItem class="px-1" v-if="$store.state.shop.market_cart.id">
        <CHeaderNavLink>
          <CButton
            size="sm"
            color="light"
            block
            @click="$store.commit('toggle', 'cartShow')"
          >
            <CIcon name="cil-basket" />
            <CBadge shape="pill" color="success">{{
              $store.state.shop.market_cart.items
            }}</CBadge>
          </CButton>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <TheHeaderDropdownAccnt />
      <TheHeaderDropdownLang />
    </CHeaderNav>

    <CSubheader class="px-3">
      <TCBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from "../containers/TheHeaderDropdownAccnt";
import TheHeaderDropdownLang from "../containers/TheHeaderDropdownLang";
// import TheHeaderDropdownNotif from "../containers/TheHeaderDropdownNotif";
// import TheHeaderDropdownTasks from "../containers/TheHeaderDropdownTasks";
// import TheHeaderDropdownMssgs from "../containers/TheHeaderDropdownMssgs";
import TCBreadcrumbRouter from "../common/TCBreadcrumbRouter";

export default {
  name: "TheHeader",
  components: {
    TheHeaderDropdownAccnt,
    TheHeaderDropdownLang,
    // TheHeaderDropdownNotif,
    // TheHeaderDropdownTasks,
    // TheHeaderDropdownMssgs,
    TCBreadcrumbRouter,
  },
  data() {
    return {
      // languages: [
      //   { flag: "us", language: "en", title: "English" },
      //   { flag: "es", language: "es", title: "Español" },
      // ],
    };
  },

  computed: {
    user() {
      return this.$auth.user() || {};
    },
  },

  methods: {
    WorkWithUs() {
      // console.log("Work with us")
      // //
      // console.log(this.user.has_roles)
      if (this.user.has_roles) {
        this.$store.commit("toggle", "asideShow");
      } else {
        this.$router.push("/newrole");
      }
    },
  },
};
</script>
