<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            v-if="user && user.image_thumb"
            v-bind:src="user.image_thumb"
            class="c-avatar-img"
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong v-if="user && user.nickname">{{ user.nickname }}</strong>
    </CDropdownHeader>
    <CDropdownItem @click="$router.push('/user/profile')">
      <CIcon name="cil-user" /> {{ $t("header.account.profile") }}
      <CBadge color="warning" class="ml-auto"> 35%</CBadge>
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/user/email')">
      <CIcon name="cil-envelope-open" /> {{ $t("header.account.mail") }}
      <CBadge color="primary" class="ml-auto"> 0</CBadge>
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/user/social')">
      <CIcon name="cil-user" /> {{ $t("header.account.social") }}
      <CBadge color="primary" class="ml-auto"> 0</CBadge>
    </CDropdownItem>
    <CDropdownItem @click="$router.push('/user/families')">
      <CIcon name="cil-user" /> {{ $t("header.account.family") }}
      <CBadge color="primary" class="ml-auto"> 0</CBadge>
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem
      @click="
        $auth.logout({
          makeRequest: true,
        })
      "
    >
      {{ $t("header.account.logout") }}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      itemsCount: 42,
    };
  },
  computed: {
    user() {
      return this.$auth.user() || {};
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
</style>
